import React from 'react'

export default function newdesign (NewComponent, OldComponent) {
  return (props) => {
    if (window.NEWDESIGN) {
      return <NewComponent {...props} />
    } else {
      return <OldComponent {...props} />
    }
  }
}
