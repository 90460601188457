import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import StartPage from './components/StartPage/StartPage'
import SupplierHome from './components/SupplierHome/SupplierHome'
import Results from './components/Results/Results'
import Cart from './components/Cart/Cart'
import SupplierRedirect from './components/SupplierRedirect/SupplierRedirect'
import ProtectedRoute from './components/Layout/ProtectedRoute'
import AppRoute from './components/Layout/AppRoute'
import AppDebugger from './components/AppDebugger/AppDebugger'

const Terms = lazy(() => import('./components/Pages/Terms'))
const DataPrivacy = lazy(() => import('./components/Pages/DataPrivacy'))
const Imprint = lazy(() => import('./components/Pages/Imprint'))
const SupplierNew = lazy(() => import('./components/SupplierNew/SupplierNew'))
const SupplierEditHome = lazy(() =>
  import('./components/SupplierAdmin/SupplierEdit/SupplierEditHome')
)
const SupplierAdmin = lazy(() =>
  import('./components/SupplierAdmin/SupplierAdmin')
)
const GetListed = lazy(() => import('./components/GetListed/GetListed'))
const MiceMomentsExpandedView = lazy(() =>
  import('./components/MiceMoments/MiceMomentsExpandedView')
)

const deliverables = [
  'hotel',
  'location',
  'restaurant',
  'transfer',
  'personal-service',
  'catering',
  'event-facility',
  'convention-bureau'
]

const Routes = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {/* The components passed to these routes are already wrapped inside a layout */}
        <ProtectedRoute
          exact
          path='/:userType/:objectID/admin/:tabs?'
          component={SupplierAdmin}
          isLoggedIn={props.isLoggedIn}
        />
        <ProtectedRoute
          exact
          path='/:userType/:objectID/admin/suppliers/:supplierID/edit/:deliverableType'
          component={SupplierEditHome}
          isLoggedIn={props.isLoggedIn}
        />
        <ProtectedRoute
          exact
          path='/:userType/:objectID/edit/:deliverableType'
          component={SupplierEditHome}
          isLoggedIn={props.isLoggedIn}
        />
        {/* END The components passed to these routes are already wrapped inside a layout */}
        <Route
          exact
          path='/login'
          component={(props) => {
            window.location.href = `/login?return_url=${props.location.state.from.pathname}`
            return null
          }}
        />
        <AppRoute
          exact
          path='/moments/:id'
          component={MiceMomentsExpandedView}
          hideBreadcrumb={false}
        />
        <AppRoute exact path='/terms' component={Terms} />
        <AppRoute exact path='/data-privacy' component={DataPrivacy} />
        <AppRoute exact path='/imprint' component={Imprint} />
        <AppRoute exact path='/get-listed' component={GetListed} />
        <AppRoute
          exact
          path='/results'
          component={Results}
          hideHeaderButtons
          hideSearchBar={false}
          hideBreadcrumb={false}
        />
        <AppRoute
          exact
          path='/moments'
          component={Results}
          filterMode='moments'
          hideHeaderButtons
          hideBreadcrumb={false}
          hideSearchBar={false}
        />
        <AppRoute exact path='/cart' component={Cart} />
        <AppRoute exact path='/suppliers/new' component={SupplierNew} />
        <AppRoute
          exact
          path='/suppliers/:objectID'
          component={SupplierRedirect}
        />
        <AppRoute
          exact
          path='/supplier/record/new_type/:objectID'
          component={SupplierNew}
        />
        <Route
          exact
          path='/supplier-home/:objectID'
          render={(props) => (
            <Redirect
              to={{
                pathname: `/hotel/${props.match.params.objectID}`,
                search: props.location.search
              }}
            />
          )}
        />
        {deliverables.map((deliverable, i) => {
          return (
            <AppRoute
              key={i}
              exact
              path={`/${deliverable}/:objectID`}
              view={`${deliverable}`}
              component={SupplierHome}
              hideBreadcrumb={false}
            />
          )
        })}
        <AppRoute exact path='/' component={StartPage} />
        <Route render={(props) => <Redirect to='/' {...props} />} />
        <Route exact path='/app-debugger' component={AppDebugger} />
      </Switch>
    </Suspense>
  )
}

export default Routes
