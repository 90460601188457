import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import queryString from 'qs'
import { redirectToSpecifiedUrl } from '../../redux/actions'
import { Tag, TagContent } from '../Tag/Tag'
import { localizeUrl } from '../../shared/utils'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { BreadcrumbLoader } from '../../shared/contentLoaders'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import MomentsIndexParams from '../../shared/momentsIndexParams'
import { extractQueryParams } from '../../services/urlStateParser'
import Breadcrumb from './Breadcrumb'
import './BreadcrumbBar.scss'

const PATHS = {
  '': {
    name: 'home',
    subPaths: ['results', 'cart', 'supplier']
  },
  results: {
    name: 'search_results',
    subPaths: ['cart', 'supplier', 'moments']
  },
  cart: {
    name: 'cart',
    subPaths: ['supplier']
  },
  supplier: {
    name: 'supplier',
    subPaths: ['cart', 'moments']
  },
  imprint: {
    name: 'imprint',
    subPaths: ['home']
  },
  terms: {
    name: 'terms_and_conditions',
    subPaths: ['home']
  },
  'get-listed': {
    name: 'Get Listed',
    subPaths: ['home']
  },
  'data-privacy': {
    name: 'privacy_policy',
    subPaths: ['home']
  },
  moments: {
    name: 'moments',
    subPaths: ['moments', 'supplier']
  }

}

const supplierPaths = ['catering', 'convention-bureau', 'facility', 'hotel', 'location', 'personal-service', 'restaurant', 'transfer', 'supplier-home']

class BreadcrumbBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      appliedFilters: []
    }
    this.applyFiltersFromParams = this.applyFiltersFromParams.bind(this)
    this.handleLocationRemove = this.handleLocationRemove.bind(this)
    this.handleRemoveAllFilters = this.handleRemoveAllFilters.bind(this)
    this.goBackIconName = this.goBackIconName.bind(this)
  }

  handleLocationRemove () {
    if (this.props.history && this.props.history.length) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: queryString.stringify({
          ...this.props.queryParams,
          location: null,
          lqt: null,
          region: null
        })
      })
    }
  }

  getBasePath (currentLocation) {
    const basePath = currentLocation.pathname.substr(1).split('/')[0]
    if (supplierPaths.includes(basePath)) {
      return 'supplier'
    }

    return basePath
  }

  getPathNameTranslation (pathname) {
    const t = this.props.t
    if (PATHS[pathname].name === 'supplier') {
      return t('breadcrumb_supplier')
    } else {
      return t(PATHS[pathname].name)
    }
  }

  newIndexParams () {
    const searchParams = extractQueryParams(this.props)
    if (this.props.filterMode === 'moments') {
      return new MomentsIndexParams(searchParams)
    } else {
      return new SuppliersIndexParams(searchParams)
    }
  }

  applyFiltersFromParams () {
    const indexParams = this.newIndexParams()
    const appliedFilters = indexParams.getAppliedFilters()
    this.setState({ appliedFilters: appliedFilters })
  }

  componentDidMount () {
    this.applyFiltersFromParams()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps !== this.props) {
      this.applyFiltersFromParams()
    }
  }

  handleRemoveAllFilters () {
    const { location, lqt, region } = this.props.queryParams
    this.props.history.push({ search: queryString.stringify({ location, lqt, region }) })
  }

  goBackIconName () {
    if (this.props.searchSession) {
      if ((this.props.searchSession.callback_url.includes('negotiations/new/preview') || this.props.searchSession.callback_url.includes('projects/wizard')) && this.props.searchSession.project_title) {
        return 'go_back_to_project'
      } else {
        return 'go_back'
      }
    } else {
      return ''
    }
  }

  render () {
    const { t } = this.props
    const pathname = this.getBasePath(window.location)
    const backIconName = this.goBackIconName()
    return this.props.isLoading ? (
      <BreadcrumbLoader />
    ) : (
      <div className='c-layout-breadcrumbs-1 breadcrumbContainer containerFluid'>
        <div className='bready'>
          {this.props.searchSession &&
            this.props.searchSession.callback_url ? (
              <div style={{ display: 'inline-block', marginRight: '15px' }}>
                <ButtonWithIcon
                  className='backToProjectButton justify-content-start'
                  onClick={() => this.props.redirectToSpecifiedUrl(
                    localizeUrl(this.props.searchSession.callback_url))}
                  icon='fa fa-arrow-left'
                  labelClassName='backToProjectButtonLabel'
                  label={t(`${backIconName}`)}
                  aria-label={t(`aria_labels.${backIconName}`)}
                />
              </div>
            ) : <div />}
          <Breadcrumb />
          {['results', 'moments'].includes(pathname) && (
            <div className='pt-1 pb-2 filter-tags'>
              {this.props.searchLocation && (
                <TagContent
                  label={this.props.searchLocation.name}
                  onClick={this.handleLocationRemove}
                />
              )}
              {this.state.appliedFilters &&
              this.state.appliedFilters.length > 0
                ? this.state.appliedFilters.map((filter, idx) => {
                  if (filter.key === 'start_date' || filter.key === 'end_date') {
                    return null
                  } else {
                    return <Tag key={filter.key} filter={filter} />
                  }
                })
                : null}
              {this.state.appliedFilters && this.state.appliedFilters.length > 0 && (
                <>
                  <TagContent label={t('clear_all')} onClick={this.handleRemoveAllFilters} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchLocation: state.app.searchLocation,
    searchSession: state.app.searchSession,
    queryParams: extractQueryParams(ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirectToSpecifiedUrl: (payload) =>
      dispatch(redirectToSpecifiedUrl(payload))
  }
}

BreadcrumbBar.propTypes = {
  searchLocation: PropTypes.object,
  searchSession: PropTypes.object
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BreadcrumbBar))
)
