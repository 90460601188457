import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import CateringGeneral from './CateringGeneral'
import ConventionBureauGeneral from './ConventionBureauGeneral'
import EventFacilityGeneral from './EventFacilityGeneral'
import HotelGeneral from './HotelGeneral'
import LocationGeneral from './LocationGeneral'
import PersonalServiceGeneral from './PersonalServiceGeneral'
import RestaurantGeneral from './RestaurantGeneral'
import TransferGeneral from './TransferGeneral'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { TextInput } from '../../../FormElements/FormElements'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import UploadImage from '../../../UploadImage/UploadImage'
import ValidationErrors from '../../../ValidationErrors/ValidationErrors'
import Loading from '../Loading'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'
import Select from 'react-select'
import Checkbox from '../../../Checkbox/Checkbox'
import { getSelectedOptionById, sortArrayByKey, areAttributesMappedToBool, scrollToTop, getButtonLabel, getButtonIcon, isButtonDisabled } from '../../../../shared/utils'
import FormStatus from '../../../FormStatus/FormStatus'
import './General.scss'
class General extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      supplier: {},
      mainImages: [],
      certificateFiles: [],
      validationErrors: null,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    }

    this.keyDeliverable = null

    this.getFormattedFormData = this.getFormattedFormData.bind(this)
    this.getValidCloudinaryImages = this.getValidCloudinaryImages.bind(this)
    this.setElementOrderForImages = this.setElementOrderForImages.bind(this)
    this.mapValuesToBool = this.mapValuesToBool.bind(this)
    this.mapValuesToObject = this.mapValuesToObject.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCo2InfoChange = this.handleCo2InfoChange.bind(this)
    this.handleSingleCo2InfoSelectChange = this.handleSingleCo2InfoSelectChange.bind(this)
    this.handleCo2CertificateUpload = this.handleCo2CertificateUpload.bind(this)
    this.handleCo2EmissionsDocumentUpload = this.handleCo2EmissionsDocumentUpload.bind(this)
    this.handleCo2CertificateRemove = this.handleCo2CertificateRemove.bind(this)
    this.handleCo2EmissionsDocumentRemove = this.handleCo2EmissionsDocumentRemove.bind(this)
    this.handleSingleSelectChange = this.handleSingleSelectChange.bind(this)
    this.handleMultipleSelectChangedKeys = this.handleMultipleSelectChangedKeys.bind(this)
    this.handleMultipleSelectChange = this.handleMultipleSelectChange.bind(this)
    this.handlePaymentSelectChange = this.handlePaymentSelectChange.bind(this)
    this.handleDeliverableChange = this.handleDeliverableChange.bind(this)
    this.handleImageUploadComplete = this.handleImageUploadComplete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAddMainImage = this.handleAddMainImage.bind(this)
    this.handleRemoveMainImage = this.handleRemoveMainImage.bind(this)
    this.handleMoveMainImage = this.handleMoveMainImage.bind(this)
    this.handleLogoUploadComplete = this.handleLogoUploadComplete.bind(this)
    this.handleAddCertificateFile = this.handleAddCertificateFile.bind(this)
    this.handleRemoveCertificateFile = this.handleRemoveCertificateFile.bind(this)
    this.handleRegionalPostCodeChange = this.handleRegionalPostCodeChange.bind(this)
    this.erroredSections = this.erroredSections.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    if (this.props.deliverableType) {
      this.keyDeliverable = `supplier_${this.props.deliverableType}`
    }

    this.initializeStateWithExistingImages()
    this.initializeStateWithExistingFormData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingImages()
      this.initializeStateWithExistingFormData()
    }
    // we only need to take action for the first change made by the user
    if (!prevState.hasUserMadeChanges && this.state.hasUserMadeChanges) {
      this.props.updateHandler(null, 'General')
      this.setState({ submitStatus: 'ready-to-save' })
    }
  }

  initializeStateWithExistingImages () {
    const supplier = this.props.supplier
    const supplierDeliverable = supplier[`supplier_${this.props.deliverableType}`]

    if (supplierDeliverable && supplierDeliverable.main_images && supplierDeliverable.main_images.length > 0) {
      this.setState({ mainImages: supplierDeliverable.main_images })
    }

    if (supplier.certificate_files && supplier.certificate_files.length > 0) {
      this.setState({ certificateFiles: supplier.certificate_files })
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      this.setState({ supplier: { ...this.props.supplier }, hasUserMadeChanges: false, submitStatus: 'nothing-to-save' })
    }
  }

  handleChange (event) {
    if (event && event.target) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      this.setState({ supplier: { ...this.state.supplier, [event.target.name]: value }, hasUserMadeChanges: true })
    }
  }

  handleCo2InfoChange (event) {
    if (event && event.target) {
      const supplier = { ...this.state.supplier }
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      supplier.co2_info[event.target.name] = value
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleSingleCo2InfoSelectChange (attributeName, option) {
    if (attributeName) {
      const supplier = { ...this.state.supplier }
      supplier.co2_info[attributeName] = option ? option.value : ''
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleCo2CertificateUpload (response) {
    if (response && response.public_id) {
      const supplier = { ...this.state.supplier }
      supplier.co2_info.co2_certificate_cloudinary_public_id = response.public_id
      supplier.co2_info.co2_certificate_file_url = response.url.replace('.pdf', '.jpg')
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleCo2EmissionsDocumentUpload (response) {
    if (response && response.public_id) {
      const supplier = { ...this.state.supplier }
      supplier.co2_info.co2_emissions_document_cloudinary_public_id = response.public_id
      supplier.co2_info.co2_emissions_document_file_url = response.url.replace('.pdf', '.jpg')
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleCo2CertificateRemove () {
    const supplier = { ...this.state.supplier }
    supplier.co2_info.co2_certificate_cloudinary_public_id = null
    supplier.co2_info.co2_certificate_file_url = null
    this.setState({ supplier: supplier, hasUserMadeChanges: true })
  }

  handleCo2EmissionsDocumentRemove () {
    const supplier = { ...this.state.supplier }
    supplier.co2_info.co2_emissions_document_cloudinary_public_id = null
    supplier.co2_info.co2_emissions_document_file_url = null
    this.setState({ supplier: supplier, hasUserMadeChanges: true })
  }

  handlePaymentSelectChange (values) {
    let paymentOptions = this.state.supplier.payment_info ? [...this.state.supplier.payment_info.payment_options] : {}
    paymentOptions = this.mapValuesToObject(paymentOptions, values)
    this.setState({ supplier: { ...this.state.supplier, payment_info: { payment_options: paymentOptions } }, hasUserMadeChanges: true })
  }

  handleAddMainImage () {
    const mainImages = [...this.state.mainImages]
    mainImages.push({ element_order: mainImages.length, cloudinary_public_id: null, url: null })
    this.setState({ mainImages: mainImages, hasUserMadeChanges: true })
  }

  handleRemoveMainImage (id) {
    const images = [...this.state.mainImages]
    const image = { ...this.state.mainImages[id] }

    image._destroy = true
    images[id] = image
    this.setState({ mainImages: images, hasUserMadeChanges: true })
  }

  handleMoveMainImage (idx, newIdx) {
    if (newIdx >= 0 && newIdx < this.state.mainImages.length) {
      const images = [...this.state.mainImages]
      const otherImage = images[newIdx]

      images[newIdx] = images[idx]
      images[idx] = otherImage

      for (let i = 0; i < images.length; i++) {
        images[i].element_order = i
      }

      this.setState({ mainImages: images, hasUserMadeChanges: true })
    }
  }

  handleRemoveLogo () {
    this.setState({ attributes: { ...this.state.supplier, new_logo_url: null }, hasUserMadeChanges: true })
  }

  handleAddCertificateFile () {
    const certificateFiles = [...this.state.certificateFiles]
    certificateFiles.push({ element_order: certificateFiles.length, cloudinary_public_id: null, url: null })
    this.setState({ certificateFiles: certificateFiles })
  }

  handleRemoveCertificateFile (id) {
    const images = [...this.state.certificateFiles]
    const image = { ...this.state.certificateFiles[id] }

    image._destroy = true
    images[id] = image
    this.setState({ certificateFiles: images })
  }

  handleMultipleSelectChangedKeys (attributeName, values, deliverable = null) {
    const supplier2 = { ...this.state.supplier }
    const target = deliverable ? { ...supplier2[this.keyDeliverable] } : supplier2

    target[attributeName] = (values || []).map((v) => { return v.key })

    if (deliverable) { supplier2[this.keyDeliverable] = target }
    this.setState({ supplier: supplier2, hasUserMadeChanges: true })
  }

  handleMultipleSelectChange (attributeName, values, deliverable = null) {
    if (attributeName) {
      let supplier = { ...this.state.supplier }
      if (deliverable) {
        const filteredOptions = this.mapValuesToObject(supplier[this.keyDeliverable][attributeName], values)
        if (areAttributesMappedToBool(attributeName)) {
          const mappedBoolValues = this.mapValuesToBool(filteredOptions)
          supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], ...mappedBoolValues }
        }
        supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], [attributeName]: filteredOptions }
      } else {
        const filteredOptions = this.mapValuesToObject(supplier[attributeName], values)
        if (areAttributesMappedToBool(attributeName)) {
          const mappedBoolValues = this.mapValuesToBool(filteredOptions)
          supplier = { ...supplier, ...mappedBoolValues }
        }
        supplier[attributeName] = filteredOptions
      }
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleSingleSelectChange (attributeName, value, deliverable = null) {
    if (attributeName && (value || value === 0)) {
      const supplier = { ...this.state.supplier }
      if (deliverable) {
        supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], [attributeName]: value }
      } else {
        supplier[attributeName] = value
      }
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleDeliverableChange (event) {
    if (event && event.target) {
      const supplier = { ...this.state.supplier }
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], [event.target.name]: value }
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleRegionalPostCodeChange (values) {
    if (values) {
      const supplier = { ...this.state.supplier }
      supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], ...values }
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleImageUploadComplete (attributeName, id, response) {
    const images = [...this.state[attributeName]]
    const image = { ...this.state[attributeName][id] }
    if (response && response.public_id) {
      if (id !== null && id >= 0) {
        image.cloudinary_public_id = response.public_id
        image.url = response.url
        images[id] = image
      } else {
        images.push({ element_order: images.length, cloudinary_public_id: response.public_id, url: response.url })
      }
      this.setState({ [attributeName]: images, hasUserMadeChanges: true })
    }
  }

  handleLogoUploadComplete (response) {
    if (response && response.url) {
      this.setState({ attributes: { ...this.state.supplier, new_logo_url: response.url }, hasUserMadeChanges: true })
    }
  }

  handleReset () {
    this.setState({
      validationErrors: null,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    })
    this.props.resetHandler()
  }

  handleSubmit () {
    this.setState({ submitStatus: 'in-progress' })

    axios.defaults.withCredentials = true

    axios
      .put(`/suppliers/${this.props.supplier.id}.json`, { ...this.getFormattedFormData(), locale: this.props.i18n.language })
      .then(response => {
        this.setState({
          validationErrors: null,
          hasUserMadeChanges: false,
          submitStatus: 'save-successful'
        })
        this.props.updateHandler(response.data, null)
      })
      .catch(error => {
        this.setState({
          validationErrors: error.response && error.response.data && error.response.data.errors,
          submitStatus: 'submit-error',
          hasUserMadeChanges: true
        })
        scrollToTop()
      })
  }

  getFormattedFormData () {
    const supplier = { ...this.state.supplier }

    supplier[this.keyDeliverable].main_images_attributes = this.setElementOrderForImages(this.getValidCloudinaryImages(this.state.mainImages))
    supplier.certificate_files_attributes = this.setElementOrderForImages(this.getValidCloudinaryImages(this.state.certificateFiles))

    const paymentInfoAttributes = {}

    supplier.payment_info.payment_options.map(option => {
      paymentInfoAttributes[option.attribute_name] = option.checked
    })

    supplier.payment_info_attributes = paymentInfoAttributes
    supplier.co2_info_attributes = { ...supplier.co2_info }

    supplier[`${this.keyDeliverable}_attributes`] = supplier[this.keyDeliverable]

    return {
      facet: 'general',
      deliverable_type: this.props.deliverableType,
      locale: this.props.i18n.language,
      supplier: supplier
    }
  }

  getValidCloudinaryImages (images) {
    return images && images.length > 0 ? images.filter(image => { return image.cloudinary_public_id !== null }) : []
  }

  setElementOrderForImages (images) {
    return images && images.length > 0 ? images.map((image, idx) => { image.element_order = idx + 1; return image }) : []
  }

  erroredSections () {
    const { validationErrors } = { ...this.state }

    if (!validationErrors) return []
    if (!validationErrors.attributes) return []

    const sections = new Set()
    validationErrors.attributes.map((attr) => {
      switch (attr) {
        case 'mp_terms_and_conditions':
        case 'data_regulation_agreement':
          sections.add('generalConsent')
          break

        default:
          break
      }
    })

    return Array.from(sections)
  }

  mapValuesToObject (attributeOptions, values) {
    return attributeOptions.map((option) => {
      return { ...option, checked: !!(values && values.find(value => value.attribute_name === option.attribute_name)) }
    })
  }

  mapValuesToBool (values) {
    let mappedValues = {}

    if (values && values.length > 0) {
      values.map(value => {
        mappedValues = { ...mappedValues, [value.attribute_name]: value.checked }
      })
    }

    return mappedValues
  }

  renderDeliverable () {
    switch (this.props.deliverableType) {
      case 'catering':
        return (
          <CateringGeneral
            supplier={this.state.supplier}
            onChange={this.handleChange}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
          />
        )
      case 'convention_bureau':
        return (
          <ConventionBureauGeneral
            supplier={this.state.supplier}
            onChange={this.handleChange}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
          />
        )
      case 'event_facility':
        return (
          <EventFacilityGeneral
            supplier={this.state.supplier}
            onChange={this.handleChange}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onMultipleSelectChangedKeys={this.handleMultipleSelectChangedKeys}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
          />
        )
      case 'hotel':
        return (
          <HotelGeneral
            supplier={this.state.supplier}
            certificateFiles={this.state.certificateFiles}
            onChange={this.handleChange}
            onCo2InfoChange={this.handleCo2InfoChange}
            onCo2InfoSingleSelectChange={this.handleSingleCo2InfoSelectChange}
            onCo2CertificateUpload={this.handleCo2CertificateUpload}
            onCo2EmissionsDocumentUpload={this.handleCo2EmissionsDocumentUpload}
            onCo2CertificateRemove={this.handleCo2CertificateRemove}
            onCo2EmissionsDocumentRemove={this.handleCo2EmissionsDocumentRemove}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onMultipleSelectChangedKeys={this.handleMultipleSelectChangedKeys}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
            onAddingCertificateFile={this.handleAddCertificateFile}
            onRemovingCertificateFile={this.handleRemoveCertificateFile}
            onImageUploadComplete={this.handleImageUploadComplete}
            uploadWidget={this.props.uploadWidget}
          />
        )
      case 'location':
        return (
          <LocationGeneral
            supplier={this.state.supplier}
            certificateFiles={this.state.certificateFiles}
            onChange={this.handleChange}
            onCo2InfoChange={this.handleCo2InfoChange}
            onCo2InfoSingleSelectChange={this.handleSingleCo2InfoSelectChange}
            onCo2CertificateUpload={this.handleCo2CertificateUpload}
            onCo2EmissionsDocumentUpload={this.handleCo2EmissionsDocumentUpload}
            onCo2CertificateRemove={this.handleCo2CertificateRemove}
            onCo2EmissionsDocumentRemove={this.handleCo2EmissionsDocumentRemove}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onMultipleSelectChangedKeys={this.handleMultipleSelectChangedKeys}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
            onAddingCertificateFile={this.handleAddCertificateFile}
            onRemovingCertificateFile={this.handleRemoveCertificateFile}
            onImageUploadComplete={this.handleImageUploadComplete}
            uploadWidget={this.props.uploadWidget}
          />
        )
      case 'personal_service':
        return (
          <PersonalServiceGeneral
            supplier={this.state.supplier}
            onChange={this.handleChange}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onMultipleSelectChangedKeys={this.handleMultipleSelectChangedKeys}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
          />
        )
      case 'restaurant':
        return (
          <RestaurantGeneral
            supplier={this.state.supplier}
            onChange={this.handleChange}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
          />
        )
      case 'transfer':
        return (
          <TransferGeneral
            supplier={this.state.supplier}
            onChange={this.handleChange}
            onSingleSelectChange={this.handleSingleSelectChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onMultipleSelectChangedKeys={this.handleMultipleSelectChangedKeys}
            onPaymentSelectChange={this.handlePaymentSelectChange}
            onDeliverableChange={this.handleDeliverableChange}
            onRegionalPostCodeChange={this.handleRegionalPostCodeChange}
          />
        )
    }
  }

  renderGeneralWithDeliverables () {
    const { t, i18n } = this.props
    return (
      <div className='containerFluid'>
        {this.state.hasUserMadeChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={getButtonIcon('reset', this.state.submitStatus, this.state.hasUserMadeChanges)}
              label={getButtonLabel('reset', this.state.submitStatus, this.state.hasUserMadeChanges)}
              className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
              onClick={this.handleReset}
              disabled={isButtonDisabled(this.state.submitStatus)}
            />
          </Col>
        ) : null}
        <Row className='m-0 mt-3 mb-3'>
          <Col className='p-0' xs={12}>
            <Card className='supplier-feature-box-round'>
              <CardBody>
                <ValidationErrors validationErrors={this.state.validationErrors} erroredSections={this.erroredSections()} />
                <Row className='m-0 mt-3 mb-3 pl-4 pr-4'>
                  {(this.state.mainImages && this.state.mainImages.length > 0) ? (
                    this.state.mainImages.map((image, idx) => (
                      image._destroy ? null : (
                        <Col key={idx} className='pl-0 pt-3' lg={3} md={4} sm={6} xs={12}>
                          <UploadImage
                            label={t('click_here_to_upload')}
                            imageUrl={image.url}
                            mode='g_auto'
                            movable
                            canMoveLeft={idx > 0}
                            canMoveRight={idx < this.state.mainImages.length - 1}
                            uploadWidget={this.props.uploadWidget}
                            onUploadComplete={response => this.handleImageUploadComplete('mainImages', idx, response)}
                            onRemove={() => this.handleRemoveMainImage(idx)}
                            onMoveLeft={() => this.handleMoveMainImage(idx, idx - 1)}
                            onMoveRight={() => this.handleMoveMainImage(idx, idx + 1)}
                          />
                        </Col>
                      )
                    ))
                  ) : (
                    <Col className='p-0 d-flex justify-content-center align-items-center' lg={3} md={4} sm={6} xs={12}>
                      <UploadImage
                        label={t('click_here_to_upload')}
                        imageUrl=''
                        mode='c_fill,g_auto'
                        uploadWidget={this.props.uploadWidget}
                        onUploadComplete={response => this.handleImageUploadComplete('mainImages', null, response)}
                      />
                    </Col>
                  )}
                </Row>
                <Row className='m-0 mt-3 mb-3 pl-4 pr-4'>
                  <Col className='p-0' xl={3} lg={4} md={4} sm={6}>
                    <ButtonWithIcon
                      className='client-theme--border-color'
                      border='1px solid'
                      label={t('add_image')}
                      icon='fa fa-plus'
                      onClick={this.handleAddMainImage}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Accordion
          id='generalAcc'
          preExpanded={this.erroredSections()}
          allowMultipleExpanded
          allowZeroExpanded
        >
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card className='supplier-feature-box-round'>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.general_property_information')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='name'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.property_name')} *`}
                            placeholder={t('supplier.edit.general.property_name')}
                            value={this.state.supplier.name}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='name2'
                            className='borderPrimary'
                            label={t('supplier.edit.general.property_name_2')}
                            placeholder={t('supplier.edit.general.property_name_2')}
                            value={this.state.supplier.name2}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='street'
                            className='borderPrimary'
                            label={`${t('street')} *`}
                            placeholder={t('street')}
                            value={this.state.supplier.street}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='post_code'
                            className='borderPrimary'
                            label={`${t('zip_code_abbreviation')} *`}
                            placeholder={t('zip_code_abbreviation')}
                            value={this.state.supplier.post_code}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='phone_number'
                            className='borderPrimary'
                            label={`${t('phone_number')} *`}
                            placeholder={t('phone_number')}
                            value={this.state.supplier.phone_number}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='url'
                            className='borderPrimary'
                            label={`${t('url')} *`}
                            placeholder={t('url')}
                            value={this.state.supplier.url}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='city'
                            className='borderPrimary'
                            label={`${t('city')} *`}
                            placeholder={t('city')}
                            value={this.state.supplier.city}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='province'
                            className='borderPrimary'
                            label={`${t('province')}`}
                            placeholder={t('province')}
                            value={this.state.supplier.province}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <label>{`${t('country')} *`}</label>
                          <Select
                            name='country_id'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={sortArrayByKey(this.state.supplier.country_id_options, `name_${i18n.language}`)}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('please_select')}
                            value={getSelectedOptionById(this.state.supplier.country_id_options, this.state.supplier.country_id)}
                            isSearchable
                            isMulti={false}
                            onChange={(value) => this.handleSingleSelectChange('country_id', value.id)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                        <Col md={6} sm={8} xs={12}>
                          <label>{t('chain')}</label>
                          <Select
                            name='chain_id'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.state.supplier.chain_id_options}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            placeholder={t('please_select')}
                            isSearchable
                            isMulti={false}
                            onChange={(value) => this.handleSingleSelectChange('chain_id', value.id)}
                            value={getSelectedOptionById(this.state.supplier.chain_id_options, this.state.supplier.chain_id)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='email'
                            className='borderPrimary'
                            label={`${t('email')} *`}
                            placeholder={t('email')}
                            value={this.state.supplier.email}
                            onChange={this.handleChange}
                          />
                        </Col>
                        {
                          (this.props.user && this.props.user.roles_symbols && this.props.user.roles_symbols.includes('employee')) ? (
                            <Col md={6} sm={8} xs={12}>
                              <TextInput
                                name='external_supplier_id'
                                className='borderPrimary'
                                label={`${t('external_supplier_id')}`}
                                placeholder={t('external_supplier_id')}
                                value={this.state.supplier.external_supplier_id}
                                onChange={this.handleChange}
                              />
                            </Col>
                          ) : null
                        }
                      </Row>
                      <Row style={{ display: 'none' }} className='mt-4 mb-4'>
                        <Col md={6} sm={8} xs={12}>
                          <UploadImage
                            label={t('upload_your_logo')}
                            imageUrl={this.state.supplier && this.state.supplier.new_logo_url}
                            attributeName='new_logo_url'
                            uploadWidget={this.props.uploadWidget}
                            onUploadComplete={response => this.handleLogoUploadComplete('new_logo_url', null, response)}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.renderDeliverable()}

          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card className='supplier-feature-box-round'>
                <CardBody>
                  <AccordionItem uid='generalConsent'>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.general_consent')}
                          {this.erroredSections().includes('generalConsent') ? <span className='error'>*</span> : null}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='m-0 mt-2 mb-2'>
                        <Col className='mt-3 mb-3' xl={1} md={1} xs={4}>
                          <Checkbox
                            id='dataProtectionAgreement'
                            name='mp_terms_and_conditions'
                            checked={this.state.supplier.mp_terms_and_conditions}
                            onChange={this.handleChange}
                            label=''
                          />
                        </Col>
                        <Col className='mt-3 mb-3' xl={11} md={11} xs={8}>
                          <label className='data-protection-agreement' htmlFor='dataProtectionAgreement'>
                            <Trans i18nKey='supplier.edit.general.mp_terms_and_conditions' components={[<Link to={`/terms?locale=${this.props.i18n.language}`} key={0} target='_blank' />]} />
                          </label>
                        </Col>
                      </Row>
                      <Row className='m-0'>
                        <Col className='mt-3 mb-3' xl={1} md={1} xs={4}>
                          <Checkbox
                            id='dataRegulationAgreement'
                            name='data_regulation_agreement'
                            checked={this.state.supplier.data_regulation_agreement}
                            onChange={this.handleChange}
                            label=''
                          />
                        </Col>
                        <Col className='mt-3 mb-3' xl={11} md={11} xs={8}>
                          <label className='data-protection-agreement' htmlFor='dataRegulationAgreement'>
                            <Trans i18nKey='supplier.edit.general.data_regulation_agreement' components={[<Link to={`/data-privacy?locale=${this.props.i18n.language}`} key={0} target='_blank' />]} />
                          </label>
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Accordion>

        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={getButtonIcon('submit', this.state.submitStatus, this.state.hasUserMadeChanges)}
              label={getButtonLabel('submit', this.state.submitStatus, this.state.hasUserMadeChanges)}
              className='btn c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
              onClick={this.handleSubmit}
              disabled={isButtonDisabled(this.state.submitStatus)}
            />
          </Col>
          <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
            <FormStatus
              id='supplierEditGeneral'
              submitStatus={this.state.submitStatus}
              errors={this.state.validationErrors}
            />
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    if (this.state.supplier && this.state.supplier.id) {
      return this.renderGeneralWithDeliverables()
    } else {
      return <Loading />
    }
  }
}

General.propTypes = {
  supplier: PropTypes.object,
  uploadWidget: PropTypes.object,
  deliverableType: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.app.user
  }
}

export default connect(mapStateToProps)(withTranslation()(General))
