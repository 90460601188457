import React from 'react'
import { startsWith } from 'lodash'
import PropTypes from 'prop-types'
import PlaceSearchAutosuggest from '../PlaceSearchAutosuggest/PlaceSearchAutosuggest'
import { useQueryParams, isSEOPath } from '../../services/urlStateParser'
import './SearchBarPlaceSuggest.scss'

function handleSearchQueryChange (queryParams, resultsFrom, searchQuery, locationId, filters = {}) {
  let pathname = queryParams.pathname
  if (pathnameShouldChange(pathname)) {
    if (resultsFrom === 'moments') {
      pathname = '/moments'
    } else {
      pathname = '/results'
    }
  }
  const params = locationId !== undefined ? { location: locationId, lqt: undefined, region: null } : { query: searchQuery }
  const paramsWithFilters = { ...params, ...filters }
  queryParams.update(paramsWithFilters, pathname)
}

function pathnameShouldChange (pathname) {
  const paths = ['/cart', '/get-started', '/supplier', '/data-privacy', '/terms',
    '/supplier-home', '/hotel', '/location', '/restaurant', '/transfer', '/imrpint',
    '/personal-service', '/catering', '/event-facility', '/convention-bureau', '/moments/']

  return paths.some(path => startsWith(pathname, path)) || isSEOPath(pathname) || pathname === '/'
}

const SearchBarPlaceSuggest = ({ resultsFrom, searchInputPlaceholder, isInsideHeader, hideSupplierSearchBar, filterMode, hidePlaceSearch }) => {
  const queryParams = useQueryParams()
  return (
    <div className='searchBarPlaceSuggestWrapper'>
      <PlaceSearchAutosuggest
        onSearch={(searchQuery, locationId, filters) => handleSearchQueryChange(queryParams, resultsFrom, searchQuery, locationId, filters)}
        placeholder={searchInputPlaceholder}
        queryParams={queryParams}
        resultsFrom={resultsFrom}
        isInsideHeader={isInsideHeader}
        hideSupplierSearchBar={hideSupplierSearchBar}
        hidePlaceSearch={hidePlaceSearch}
        filterMode={filterMode}
        delay={400}
      />
    </div>
  )
}

SearchBarPlaceSuggest.propTypes = {
  onFilterBarToggle: PropTypes.func,
  searchInputPlaceholder: PropTypes.string
}

export default SearchBarPlaceSuggest
