import React, { Component } from 'react'
import axios from 'axios'
import { withRouter, Redirect } from 'react-router-dom'
import * as queryString from 'qs'

const ALLOWED_DELIVERABLE_TYPES = {
  hotel: 'hotel',
  location: 'location',
  restaurant: 'restaurant',
  transfer: 'transfer',
  personal_service: 'personal-service',
  catering: 'catering',
  event_facility: 'event-facility',
  convention_bureau: 'convention-bureau'
}

class SupplierRedirect extends Component {
  constructor (props) {
    super(props)
    this.state = { deliverableTypeToRedirect: null }
  }

  componentDidMount () {
    this.getCorrectDeliverable()
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.getCorrectDeliverable()
    }
  }

  getCorrectDeliverable () {
    if (!this.props.match.params.objectID) {
      return
    }

    const supplierID = this.props.match.params.objectID

    this.setState(
      { deliverableTypeToRedirect: null },
      () => {
        axios
          .get(`/suppliers/${supplierID}.json`)
          .then((response) => {
            const params = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true })
            let requestDeliverableType = (params.context && params.context.service_type_name) || 'hotel'

            if (!response.data[`supplier_${requestDeliverableType}`]) {
              requestDeliverableType = Object.keys(ALLOWED_DELIVERABLE_TYPES).filter(k => response.data[`supplier_${k}`])[0]
            }

            const allowedDeliverable = ALLOWED_DELIVERABLE_TYPES[requestDeliverableType]
            this.setState({ deliverableTypeToRedirect: allowedDeliverable })
          })
          .catch((error) => {
            console.log('fetching supplier error', error)
            this.setState({ deliverableTypeToRedirect: 'hotel' })
          })
      }
    )
  }

  render () {
    if (this.state.deliverableTypeToRedirect) {
      return (
        <Redirect
          to={{
            pathname: `/${this.state.deliverableTypeToRedirect}/${this.props.match.params.objectID}`,
            search: this.props.location.search
          }}
        />
      )
    } else {
      return (<>...</>)
    }
  }
}

export default withRouter(SupplierRedirect)
