import { getI18n } from 'react-i18next'
import { isEmpty } from 'lodash'
import { paymentMethods } from './hotel'
require('dayjs/locale/de')
var isoWeek = require('dayjs/plugin/isoWeek')
var dayjs = require('dayjs')
dayjs.extend(isoWeek)
const getHour = date => dayjs(date).format('H:mm')

export default ({
  supplier,
  feedback
}) => {
  if (isEmpty(supplier)) return

  const i18n = getI18n()
  const locale = i18n.language
  const address = supplier.address
  const payments = supplier.payment_info
  const supplierImage = supplier.supplier_restaurant_general_images[0] ? supplier.supplier_restaurant_general_images[0].url : ''
  const type = supplier.supplier_restaurant
  const cuisines = Object.keys(type)
    .filter(k => k.startsWith('cuisine_'))
    .filter(k => type[k])
    .map(k => k.replace('cuisine_', ''))

  return {
    '@context': 'http://schema.org',
    '@type': 'Restaurant',
    name: supplier.name,
    url: `https://www.miceportal.com/restaurant/${supplier.id}?locale=${locale}`,
    email: supplier.email,
    telephone: supplier.phone_number,
    image: supplierImage,
    address: {
      '@type': 'PostalAddress',
      addressLocality: address.city,
      addressRegion: address.province,
      postalCode: address.post_code,
      streetAddress: address.street
    },
    openingHours: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day, index) => {
      dayjs.locale(locale)
      const dayOfWeek = dayjs().isoWeekday(index).format('dddd')
      const dayFrom = type[`${day}_from`]
      const dayUntil = type[`${day}_until`]
      return dayFrom && dayUntil ? `${dayOfWeek} ${getHour(dayFrom)}-${getHour(dayUntil)}` : false
    }).filter(day => day),
    ...(!isEmpty(feedback) && feedback.summary && feedback.summary.feedback_amount > 0 ? {
      aggregateRating: {
        '@type': 'AggregateRating',
        reviewCount: feedback && feedback.summary ? feedback.summary.feedback_amount : null,
        ratingValue: feedback && feedback.summary ? feedback.summary.feedback_average : null,
        bestRating: 5
      }
    } : {}),
    servesCuisine: cuisines,
    menu: '',
    paymentAccepted: Object.keys(payments).filter(p => payments[p]).map(p => paymentMethods[p])
  }
}
