import React from 'react'
import './SupplierOverlayBox.scss'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import defaultLogo from '../../assets/images/logo.png'
import SupplierContract from '../SupplierContract/SupplierContract'
import AddToCart from '../Cart/AddToCart'
import { Link, withRouter } from 'react-router-dom'
import Can from '../Layout/Can'
import * as qs from 'qs'
import SupplierMarkup from '../SupplierMarkup/SupplierMarkup'

class SupplierOverlayBox extends React.Component {
  linkToEdit () {
    const query = this.props.location.search
    const params = qs.parse(query, { ignoreQueryPrefix: true })
    const view = this.props.view.replace(/-/g, '_')

    if (params.smId) {
      return `/sales_managers/${params.smId}/admin/suppliers/${this.props.supplier.id}/edit/${view}`
    }

    return `/suppliers/${this.props.supplier.id}/edit/${view}`
  }

  render () {
    const { i18n } = this.props
    return (
      <div
        className='c-feature-16-container c-bg-dark c-bg-opacity-5 supplierBox-radius'
      >
        <div className='supplierOverlayBoxRatingContainer client-theme--color'>
          <SupplierMarkup hit={this.props.hit} />
        </div>
        <h2
          className='c-feature-16-title c-font-white hotel c-font-uppercase'
          style={{
            fontWeight: 600,
            fontSize: '24px !important',
            marginBottom: '0px',
            marginTop: '0px'
          }}
        >
          {this.props.hit && this.props.hit.name}
          <Can perform='manage' on='all'>
            <Link
              to={this.linkToEdit()}
            >
              {' '}<i className='fa fa-edit supplier-edit' aria-hidden='true' />
            </Link>
          </Can>
        </h2>
        <p className='c-feature-16-desc c-font-grey' style={{ fontSize: '14px' }}>
          {this.props.supplier && this.props.supplier.address
            ? `${this.props.supplier.address.street}, ${this.props.supplier.address.post_code} ${
            this.props.supplier.address.city
            },
              ${this.props.supplier.address.country[`name_${i18n.language}`]}`
            : ''}
        </p>
        <div>
          <SupplierContract
            contracts={this.props.hit.contracts}
            contractsWrapperClass='supplierOverlayBoxcontractsWrapper'
            contractMainPriceClass='supplierOverlayBoxContractMainPrice'
            contractMainServiceClass='supplierOverlayBoxContractMainService'
            contractMainContainerClass='supplierOverlayBoxContractMainContainer'
            projectStartDateUnix={this.props.projectStartDateUnix}
          />
        </div>
        <Row className='p-0 m-0'>
          <AddToCart
            hit={this.props.hit}
            notInCartButtonWidth='70%'
            addButtonPadding='8px 8px'
            deleteIconStyle={{ color: '#FFFFFF' }}
            type={this.props.view}
          />
        </Row>
        <img
          // TODO LOGO
          src={this.props.logo ? this.props.logo.url : defaultLogo}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            maxWidth: '70px',
            height: 'auto'
          }}
          alt=''
        />
      </div>
    )
  }
}

SupplierOverlayBox.propTypes = {
  hit: PropTypes.object,
  supplier: PropTypes.object,
  logo: PropTypes.string,
  projectStartDateUnix: PropTypes.number
}

export default withTranslation()(withRouter(SupplierOverlayBox))
