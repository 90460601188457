import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import './SupplierHome.scss'
import { Row } from 'reactstrap'
import SupplierStickyActionBar from '../SupplierStickyActionBar/SupplierStickyActionBar'
import SupplierMainImageGallery from '../SupplierMainImageGallery/SupplierMainImageGallery'
import SupplierContactBox from '../SupplierContactBox/SupplierContactBox'
import { scrollToTop, getSupplierTypeFromMapping, getDeliverableSpecificImages } from '../../shared/utils'
import { SupplierHomeLoader } from '../../shared/contentLoaders'
import { toggleStickyBottomBar, getSupplierFromAlgolia } from '../../redux/actions'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { extractQueryParams } from '../../services/urlStateParser'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import { withTranslation } from 'react-i18next'
import DetailsTabContent from '../DetailsTabContent/DetailsTabContent'
import AmenitiesTabContent from '../AmenitiesTabContent/AmenitiesTabContent'
import ConferenceTabContent from '../ConferenceTabContent/ConferenceTabContent'
import RoomsTabContent from '../RoomsTabContent/RoomsTabContent'
import MiceMomentsTabContent from '../MiceMomentsTabContent/MiceMomentsTabContent'
import StructuredDataGenerator from '../../services/structuredDataSchema'
import { wrapAsAnInstanceOf } from '../../config/ability'
import { Helmet } from 'react-helmet'

class SupplierHome extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: true,
      hit: {},
      supplier: {},
      clientFeedbacks: {},
      clientContracts: {},
      stickyBottomBar: true
    }
    this.listenToScroll = this.listenToScroll.bind(this)
    this.setStructuredData = this.setStructuredData.bind(this)
  }

  getTabsToRender () {
    if (this.props.view) {
      switch (this.getDeliverableType()) {
        case 'hotel':
          return { details: true, amenities: true, conference: true, rooms: true, miceMoments: true }
        case 'location':
          return { details: true, amenities: true, conference: true, rooms: false, miceMoments: true }
        case 'restaurant':
          return { details: true, amenities: false, conference: false, rooms: false, miceMoments: true }
        case 'transfer':
          return { details: true, amenities: false, conference: false, rooms: false, miceMoments: true }
        case 'personal_service':
          return { details: true, amenities: false, conference: false, rooms: false, miceMoments: true }
        case 'catering':
          return { details: true, amenities: false, conference: false, rooms: false, miceMoments: true }
        case 'event_facility':
          return { details: true, amenities: false, conference: false, rooms: false, miceMoments: true }
        case 'convention_bureau':
          return { details: true, amenities: false, conference: false, rooms: false, miceMoments: true }
      }
    }
  }

  getSupplier (objectID) {
    axios
      .get(`/suppliers/${objectID}.json`)
      .then((response) => {
        this.setState(() => ({
          supplier: wrapAsAnInstanceOf(response.data, 'Supplier'),
          isLoading: false
        }), () => this.setStructuredData())
      })
      .catch((error) => {
        console.log('fetching supplier error', error)
        this.setState({ isLoading: false, supplier: {}, error: true, errorMessage: error })
      })
  }

  getClientFeedbacks (objectID) {
    axios
      .get(`/suppliers/${objectID}/feedback.json?client_domain=${window.CURRENT_SUBDOMAIN}`)
      .then((response) => {
        this.setState(() => ({ clientFeedbacks: response.data }))
      })
      .catch((error) => {
        console.log('login error', error)
        this.setState({ error: true, errorMessage: error })
      })
  }

  getClientContracts (objectID) {
    const searchParams = extractQueryParams(this.props)
    const suppliersIndexParams = new SuppliersIndexParams(searchParams)
    const startDate = suppliersIndexParams.getStartDate()
    axios
      .get(`/suppliers/${objectID}/contracts.json?client_domain=${window.CURRENT_SUBDOMAIN}&deliverable_type=${this.getDeliverableType()}&start_date=${startDate}`)
      .then((response) => {
        this.setState(() => ({ clientContracts: response.data }))
      })
      .catch((error) => {
        console.log('login error', error)
        this.setState({ error: true, errorMessage: error })
      })
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenToScroll)
    this.props.getSupplierFromAlgolia(this.props.match.params.objectID)
    this.getSupplier(this.props.match.params.objectID)
    this.getClientFeedbacks(this.props.match.params.objectID)
    this.getClientContracts(this.props.match.params.objectID)
    scrollToTop()
  }

  componentDidUpdate (prevProps, prevState) {
    // NOTE: we are re-loading (from backend) supplier's data even if only the view did change. Is it necessary?
    if (prevProps.match.params.objectID !== this.props.match.params.objectID || prevProps.view !== this.props.view) {
      if (!prevState.isLoading) {
        this.setState({ isLoading: true })
      }

      this.props.getSupplierFromAlgolia(this.props.match.params.objectID)
      this.getSupplier(this.props.match.params.objectID)
      this.getClientFeedbacks(this.props.match.params.objectID)
      this.getClientContracts(this.props.match.params.objectID)
      scrollToTop()
      this.setStructuredData()
    }
    if (this.locale !== this.props.i18n.language) {
      this.setStructuredData()
    }
  }

  setStructuredData () {
    const { supplier, clientFeedbacks } = this.state

    if (!isEmpty(supplier)) {
      StructuredDataGenerator.forSupplierHome(this.props.view, {
        supplier,
        feedback: clientFeedbacks
      })
      this.locale = this.props.i18n.language
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll () {
    if (window.pageYOffset > 95) {
      if (!this.props.isStickyBottomBarActive) {
        this.props.toggleStickyBottomBar(true)
      }
    } else {
      if (this.props.isStickyBottomBarActive) {
        this.props.toggleStickyBottomBar(false)
      }
    }
  }

  getDeliverableType () {
    if (this.props.view) {
      return this.props.view.replace(/-/g, '_')
    }
  }

  getDeliverable () {
    if (this.props.view && this.state.supplier) {
      return this.state.supplier[`supplier_${this.getDeliverableType()}`]
    }
  }

  render () {
    const { t, i18n } = this.props
    const detailsKey = 'supplier.home.tabs.details.'
    const amenitiesKey = 'supplier.home.tabs.amenities.'
    const conferenceKey = 'supplier.home.tabs.conference_spaces.'
    const searchParams = extractQueryParams(this.props)
    const suppliersIndexParams = new SuppliersIndexParams(searchParams)
    const startDate = suppliersIndexParams.getStartDate()
    const startDateUnix = suppliersIndexParams.getStartDateUnix()

    if (this.props.isSearchStalled || this.state.isLoading) {
      return <SupplierHomeLoader />
    } else if (this.state.supplier.id) {
      const tabsToRender = this.getTabsToRender() || {}
      return (
        <div className='c-container supplierHome'>
          <Helmet>
            <title>{i18n.t('page_head.titles.for_supplier', { supplier: this.state.supplier.name })}</title>
            <meta name='description' content={i18n.t('page_head.meta_desc.for_supplier', { supplier: this.state.supplier.name })} />
          </Helmet>
          {
            this.props.supplierHit &&
            this.props.supplierHit.deliverable_types &&
            this.props.supplierHit.deliverable_types.length > 1
              ? (
                <Row className='m-0 mt-3'>
                  <div className='w-100 d-flex justify-content-around list-group-horizontal list-group-border'>
                    {this.props.supplierHit.deliverable_types.map((deliverable, idx) => (
                      <Link
                        key={idx}
                        className={`h4 text-uppercase list-group-item mice-tab list-group-item-action text-center rounded-0 ${
                        this.props.history.location.pathname.includes(getSupplierTypeFromMapping(deliverable)) ? 'active disabled' : null
                        }`}
                        to={`/${getSupplierTypeFromMapping(deliverable)}/${this.props.supplierHit.objectID}`}
                      >
                        {getSupplierTypeFromMapping(deliverable)}
                      </Link>
                    ))}
                  </div>
                </Row>
              ) : null
          }
          <Row className='m-0'>
            <SupplierMainImageGallery
              hit={this.props.supplierHit || {}}
              supplier={this.state.supplier}
              logo={this.state.supplier && this.state.supplier.logo ? this.state.supplier.logo.url : null}
              galleryImages={getDeliverableSpecificImages((this.props.supplierHit || {}).thumbnails || [], this.props.view)}
              view={this.props.view}
              projectStartDateUnix={startDateUnix}
              showOverlayBox
            />
          </Row>
          <Row className='mb-3'>
            <Tabs defaultIndex={0}>
              <TabList>
                {tabsToRender.details ? <Tab>{t(`${detailsKey}tab_heading`)}</Tab> : null}
                {tabsToRender.amenities ? <Tab>{t(`${amenitiesKey}tab_heading`)}</Tab> : null}
                {tabsToRender.conference ? <Tab>{t(`${conferenceKey}tab_heading`)}</Tab> : null}
                {tabsToRender.rooms ? <Tab>{t('rooms')}</Tab> : null}
                {tabsToRender.miceMoments ? <Tab>{t('mice_moments')}</Tab> : null}
              </TabList>
              {tabsToRender.details ? (
                <TabPanel>
                  <DetailsTabContent
                    hit={this.props.supplierHit || {}}
                    supplier={this.state.supplier}
                    clientFeedbacks={this.state.clientFeedbacks}
                    clientContracts={this.state.clientContracts}
                    startDate={startDate}
                    view={this.props.view}
                  />
                </TabPanel>
              ) : null}
              {tabsToRender.amenities ? (
                <TabPanel>
                  <AmenitiesTabContent
                    hit={this.props.supplierHit || {}}
                    supplier={this.state.supplier}
                    clientFeedbacks={this.state.clientFeedbacks}
                    clientContracts={this.state.clientContracts}
                    view={this.props.view}
                    startDate={startDate}
                  />
                </TabPanel>
              ) : null}
              {tabsToRender.conference ? (
                <TabPanel>
                  <ConferenceTabContent
                    hit={this.props.supplierHit || {}}
                    supplier={this.state.supplier}
                    clientFeedbacks={this.state.clientFeedbacks}
                    clientContracts={this.state.clientContracts}
                    startDate={startDate}
                    view={this.props.view}
                  />
                </TabPanel>
              ) : null}
              {tabsToRender.rooms ? (
                <TabPanel>
                  <RoomsTabContent
                    hit={this.props.supplierHit || {}}
                    supplier={this.state.supplier}
                    clientFeedbacks={this.state.clientFeedbacks}
                    clientContracts={this.state.clientContracts}
                    startDate={startDate}
                  />
                </TabPanel>
              ) : null}
              {tabsToRender.miceMoments ? (
                <TabPanel>
                  <MiceMomentsTabContent
                    supplier={this.state.supplier}
                    deliverable={this.getDeliverable()}
                  />
                </TabPanel>
              ) : null}
            </Tabs>
          </Row>
          <SupplierContactBox
            supplier={this.state.supplier}
          />
          <SupplierStickyActionBar
            hit={this.props.supplierHit || {}}
            logo={this.state.supplier && this.state.supplier.logo ? this.state.supplier.logo.url : null}
            projectStartDateUnix={startDateUnix}
          />
        </div>
      )
    } else {
      return (
        <div style={{ paddingTop: '30px' }} className='container-fluid supplierHome'>
          <h1>404 - Not Found</h1>

          {
            (this.props.user && this.props.user.roles_symbols && this.props.user.roles_symbols.includes('employee')) ? (
              <a
                className='btn c-btn btn-md c-theme-btn client-theme--bg'
                href={`/admin/suppliers/${this.props.match.params.objectID}/edit?locale=${i18n.language}`}
              >
                <i className='fa fa-link' />
                {t('supplier.admin.settings.supplier_activation')}
              </a>
            ) : null
          }
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isStickyBottomBarActive: state.app.isStickyBottomBarActive,
    supplierHit: state.app.supplier,
    user: state.app.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleStickyBottomBar: payload => dispatch(toggleStickyBottomBar(payload)),
    getSupplierFromAlgolia: (payload) => dispatch(getSupplierFromAlgolia(payload))
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SupplierHome))
