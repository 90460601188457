import React, { Component } from 'react'
import { withLanguageSwitcher } from '../../shared/languageHelpers'
import Can from '../Layout/Can'
import micePotalLogo from '../../assets/images/mice-portal-logo.png'

class AppDebugger extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({ [name]: value })
  }

  render () {
    if (this.state.shouldGenerateRenderError) {
      throw new Error(this.state.renderError)
    }

    return (
      <>
        <div className='d-flex align-items-stretch justify-content-center mt-5'>
          <div>
            <img src={micePotalLogo} alt='' />
          </div>
        </div>

        <Can perform='manage' on='all'>
          <div className='container'>
            <div className='row justify-content-center mt-5'>
              <div className='col-6'>
                <div className='form-group'>
                  <label>Generate render error</label>
                  <input type='text' className='form-control' name='renderError' onChange={this.handleChange} />
                  <small id='emailHelp' className='form-text text-muted'>This should generate a render error with the input message</small>
                </div>
                <button className='btn btn-primary' onClick={() => this.handleChange({ target: { name: 'shouldGenerateRenderError', value: true } })}>
                  Generate error
                </button>
              </div>
            </div>
          </div>
        </Can>
      </>
    )
  }
}

export default withLanguageSwitcher(AppDebugger)
