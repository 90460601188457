import { getI18n } from 'react-i18next'
import defaultThumbnail from '../assets/images/blank-hotel.jpg'
import * as queryString from 'qs'
import { transform, isEqual, isArray, isObject } from 'lodash'
import { searchIndexMiceMoment } from '../services/algolia'
import { getGoogleMaps } from '../services/googleMaps.js'
import axios from 'axios'

export const deepDifference = (origObj, newObj) => {
  function changes (newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

const uuidv4 = require('uuid/v4')

export const isLocalhost = window && window.location.host.indexOf('localhost') > -1
export const UUID = uuidv4

export const truncate = (str, len = 45, ommission = '...') => {
  if (typeof str !== 'string') {
    return str
  }

  const suf = str.length > len ? ommission : ''
  return str.substring(0, len) + suf
}

export const scrollToTop = () => {
  if (window) {
    window.scrollTo(0, 0)
  }
}

export const scrollToRef = (ref) => {
  if (window) {
    window.scrollTo(0, ref.current.offsetTop)
  }
}

export const filterObjectKeys = (obj, permitedKeys) => {
  return Object.assign({}, ...permitedKeys.map(permitedKey => ({ [permitedKey]: obj[permitedKey] })))
}

export const getSupplierTypeMapping = (type) => {
  switch (type) {
    case 'catering':
      return 'SupplierCatering'

    case 'convention-bureau':
    case 'convention_bureau':
      return 'SupplierConventionBureau'

    case 'event-facility':
    case 'event_facility':
      return 'SupplierEventFacility'

    case 'hotel':
      return 'SupplierHotel'

    case 'location':
      return 'SupplierLocation'

    case 'personal-service':
    case 'personal_service':
      return 'SupplierPersonalService'

    case 'restaurant':
      return 'SupplierRestaurant'

    case 'transfer':
      return 'SupplierTransfer'

    default:
      return null
  }
}

export const getSupplierTypeFromMapping = (type) => {
  switch (type) {
    case 'SupplierHotel':
      return 'hotel'

    case 'SupplierLocation':
      return 'location'

    case 'SupplierPersonalService':
      return 'personal-service'

    case 'SupplierEventFacility':
      return 'event-facility'

    case 'SupplierCatering':
      return 'catering'

    case 'SupplierRestaurant':
      return 'restaurant'

    case 'SupplierTransfer':
      return 'transfer'

    case 'SupplierConventionBureau':
      return 'convention-bureau'

    default:
      return null
  }
}

export const getSuitableNameFromMapping = (translatedSuitable, language) => {
  // Keep it synchronized with the translations supplier.suitables.*
  const suitables = {
    de: {
      'Agile Projektmeetings': 'agile_projectmeetings',
      BarCamp: 'barcamp',
      Weihnachtsfeier: 'christmasparty',
      Sportveranstaltung: 'competition',
      Kulturveranstaltung: 'concert',
      Konferenz: 'conference',
      Kongress: 'congress',
      'Corona Stornierungsoption': 'crisis_cancellation',
      Kundenevent: 'customer_event',
      Event: 'event',
      Ausstellung: 'exhibition',
      Messe: 'fair',
      'Fish Bowl': 'fishbowl',
      'Best Practise & Fuckup Night': 'fuckup_night',
      Galaveranstaltung: 'gala',
      'Green Meeting': 'green_meeting',
      Ignite: 'ignite',
      Incentive: 'incentive',
      'Rahmenprogramm indoor': 'indoor_programm',
      'Kick Off': 'kick_off',
      Meeting: 'meeting',
      'Work Space': 'office_space',
      'Outdoor-Veranstaltung': 'openair',
      'Open Mouth': 'open_mouth',
      'Open space': 'openspace',
      'Rahmenprogramm outdoor': 'outdoor_programm',
      Firmenfeier: 'party',
      'Pecha Kucha': 'pecha_kucha',
      Produktpräsentation: 'productpresentation',
      Roadshow: 'roadshow_event',
      Seminar: 'seminar',
      'Shared Office': 'shared_office',
      'Speed Geeking': 'speed_geeeking',
      Symposium: 'symposium',
      Teamerlebnis: 'teambuilding',
      Hausmesse: 'trade_fair_event',
      'Walt Disney Strategie': 'walt_disney',
      'Warp Conference': 'warponference',
      Workshop: 'workshop',
      'World Café': 'worldcafe',
      'Hybrides Meeting': 'hybrid_meetings',
      'Hybrides Event': 'location_hybrid_meetings',
      'Virtuelles Event': 'virtual_meetings',
      'Working Lunch': 'working_lunch',
      'Round Table': 'round_table',
      'Kamingespräch / Kaminabend': 'fireplace_evening',
      'Digitale Event': 'digital_meeting',
      Schulung: 'training',
      'Assessment-Center': 'assesment_center',
      Coaching: 'coaching',
      Kreativveranstaltung: 'creative_workshop',
      Webinar: 'online_webinar'
    },
    en: {
      'Agile meetings': 'agile_projectmeetings',
      BarCamp: 'barcamp',
      'Christmas party': 'christmasparty',
      'sports event': 'competition',
      'culture event / cultural event': 'concert',
      Conference: 'conference',
      Congress: 'congress',
      'Corona cancellation option': 'crisis_cancellation',
      'Company Event': 'customer_event',
      Event: 'event',
      Exhibition: 'exhibition',
      Fair: 'fair',
      'Fish Bowl': 'fishbowl',
      'Best Practise & Fuckup Night': 'fuckup_night',
      Gala: 'gala',
      'Green meeting': 'green_meeting',
      Ignite: 'ignite',
      Incentive: 'incentive',
      'Indoor program': 'indoor_programm',
      'Kick off': 'kick_off',
      Meeting: 'meeting',
      'Work Space': 'office_space',
      'outdoor event': 'openair',
      'Open mouth': 'open_mouth',
      'Open space': 'openspace',
      'Outdoor program': 'outdoor_programm',
      'Company party': 'party',
      'Pecha Kucha': 'pecha_kucha',
      'Product presentation': 'productpresentation',
      roadshow: 'roadshow_event',
      Seminar: 'seminar',
      'Shared office': 'shared_office',
      'Speed Geeking': 'speed_geeeking',
      Symposium: 'symposium',
      'teambuilding / team experience': 'teambuilding',
      'In-house exhibition': 'trade_fair_event',
      'Walt Disney Strategy': 'walt_disney',
      'Warp Conference': 'warponference',
      Workshop: 'workshop',
      'World Cafe': 'worldcafe',
      'Hybrid Meeting': 'hybrid_meetings',
      'Hybrid Event': 'location_hybrid_meetings',
      'Virtual Event': 'virtual_meetings',
      'Working Lunch': 'working_lunch',
      'Round Table': 'round_table',
      'Fireplace talk / chat / evening': 'fireplace_evening',
      'virtual / digital events': 'digital_meeting',
      training: 'training',
      'Assessment-Center': 'assesment_center',
      Coaching: 'coaching',
      'creative event / creativity event': 'creative_workshop',
      'webinar / online seminar': 'online_webinar'
    }
  }
  return suitables[language][translatedSuitable] || null
}

export const getActiveContract = (contracts, projectStartDateUnix) => {
  const activeClientContracts = getValidContracts(contracts, projectStartDateUnix)

  let maxStartDateUnix = 0
  let activeContract

  activeClientContracts.forEach((contract) => {
    if (contract.valid_from_unix >= maxStartDateUnix && contract.main_service !== '') {
      maxStartDateUnix = contract.valid_from_unix
      activeContract = contract
    }
  })

  return activeContract
}

export const getValidContracts = (contracts, projectStartDateUnix) => {
  if (!window.CURRENT_SUBDOMAIN) return []

  const clientContracts = contracts && contracts[window.CURRENT_SUBDOMAIN]

  const activeContractsOnProjectDate = (clientContracts || []).filter((contract) => {
    return contract.valid_from_unix <= projectStartDateUnix && contract.valid_until_unix >= projectStartDateUnix
  })

  return activeContractsOnProjectDate
}

export const hasValidContracts = (contracts, projectStartDateUnix) => {
  return getValidContracts(contracts, projectStartDateUnix).length > 0
}

export const localizeUrl = (link) => {
  const i18n = getI18n()
  const url = new URL(link)
  const params = new URLSearchParams(url.search)

  params.set('locale', i18n.language)
  url.search = params.toString()

  return url.href
}

export const getSearchCompletePath = (options, queryParams) => {
  const params = new URLSearchParams('')

  if (options.searchLocation) {
    params.set(
      'location_places',
      JSON.stringify([
        {
          place_id: options.searchLocation.placeId,
          name: options.searchLocation.name
        }
      ])
    )
  }
  if (queryParams) {
    return `/search/complete?${params.toString()}&${queryString.stringify(queryParams)}`
  }
  return `/search/complete?${params.toString()}`
}

export const addScriptTag = (attributes) => {
  let options = {}

  if (typeof attributes === 'string') {
    options.src = attributes
  } else {
    options = attributes
  }

  const scriptTag = document.createElement('script')

  for (const attr in options) {
    if (options.hasOwnProperty(attr)) { // eslint-disable-line
      scriptTag[attr] = options[attr]
    }
  }

  if (scriptTag.childNodes.length || scriptTag.src) {
    document.head.appendChild(scriptTag)
  }
}

export const isValidTenderType = (deliverableTypes = [], type) => {
  if (deliverableTypes && deliverableTypes.length > 0) {
    return deliverableTypes.includes(getSupplierTypeMapping(type))
  } else {
    return false
  }
}

export const getSupplierURL = (params, objectID, deliverableTypes) => {
  // TODO: this function has to be refactored to avoid
  // bugs when we move to complete restful paths
  const types = deliverableTypes || []
  const paramsType = params && params.type
  const typeIsPresent = paramsType && types.some(type => getSupplierTypeFromMapping(type) === paramsType)
  const projectDateParam = params && params.start_date ? `?start_date=${params.start_date}` : ''

  if (typeIsPresent) {
    return `/${paramsType}/${objectID}${projectDateParam}`
  } else {
    if (deliverableTypes) {
      if (deliverableTypes.includes('SupplierHotel')) {
        return `/hotel/${objectID}${projectDateParam}`
      } else if (deliverableTypes.includes('SupplierLocation')) {
        return `/location/${objectID}${projectDateParam}`
      } else {
        return `/${getSupplierTypeFromMapping(deliverableTypes[0])}/${objectID}${projectDateParam}`
      }
    }
  }
  return `/hotel/${objectID}`
}

export const getAccountUrl = (user) => {
  if (user) {
    const path = getAdminPath(user)

    if (path.startsWith('/suppliers') || path.startsWith('/sales_managers')) {
      return path
    }
  }

  return getAppUrl('katalysator')
}

export const getAdminPath = (user) => {
  if (user && user.is_sales_manager) {
    return `/sales_managers/${user.konvenit_user_id}/admin/`
  }

  const isSupplier = user && user.roles_symbols && user.roles_symbols.includes('supplier')
  if (isSupplier && user.konvenit_object_id) {
    return `/suppliers/${user.konvenit_object_id}/admin/`
  }

  return window.location.pathname.startsWith('/login') ? '/' : window.location.pathname
}

export const getAppUrl = (app) => {
  let appHost
  let shopUrl
  const { host } = window.location

  switch (app) {
    case 'katalysator':
      appHost = host.replace('miceplace.', '')
      shopUrl = window.SHOP_URL || `https://${appHost}`

      return `${shopUrl}/home`
    default:
      break
  }
}

export const getLoginRedirectUrl = (user) => {
  const subdomain = user.client_domain || window.CURRENT_SUBDOMAIN
  const queryParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true })
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '0.0.0.0'

  if (isLocalhost && subdomain) {
    queryParams.client_domain = subdomain
  }

  const search = queryString.stringify(queryParams, { addQueryPrefix: true })
  const path = `${getAdminPath(user)}${search}`

  if (isLocalhost || window.location.host === 'www.miceportal.com') {
    return `${window.location.protocol}//${window.location.host}${path}`
  }

  const hostContainSubdomain = window.location.host.startsWith(subdomain)
  const subdomainWithDot = subdomain && !hostContainSubdomain ? `${subdomain}.` : ''

  return `${window.location.protocol}//${subdomainWithDot}${window.location.host}${path}`
}

export const getLogoUrl = (hit) => {
  if (hit.thumbnails && hit.thumbnails.length > 0) {
    return hit.thumbnails[0].thumbnail
  } else if (hit.logos && hit.logos.length > 0) {
    return hit.logos[0].thumbnail
  } else {
    return defaultThumbnail
  }
}

export const asciiTranslate = (str) => (
  str.normalize('NFKD').replace(/[\u0300-\u036F]/g, '')
)

export const sortArrayByKey = (array, key) => {
  array = array || []

  return array.sort((object1, object2) => {
    const string1 = object1[key] || ''
    const string2 = object2[key] || ''

    return string1.localeCompare(string2)
  })
}

export const getSelectedOption = (options, selectedValue) => getSelectedOptionByKey(options, selectedValue, 'value')

export const getSelectedOptionById = (options, selectedValue) => getSelectedOptionByKey(options, selectedValue, 'id')

export const getSelectedOptionByKey = (options, selectedValue, key) => {
  if (options && options.length > 0) {
    return options.find((option) => { return selectedValue === option[key] })
  }
  return undefined
}

export const getSelectedOptions = (options) => {
  if (options && options.length > 0) {
    return options.filter((option) => { return option.checked })
  }
  return []
}

export const mapKeysToOptions = (keys, options) => {
  return options.filter((option) => { return keys.includes(option.key) })
}

export const areAttributesMappedToBool = (attributeName) => {
  switch (attributeName) {
    case 'locateds':
    case 'certificates_options':
    case 'cuisine':
    case 'suitable_fors':
    case 'characteristics':
    case 'equipment_amenities':
    case 'amenities':
    case 'wellness_amenities':
    case 'sport_amenities':
    case 'gastronomy_amenities':
      return true
    default:
      return false
  }
}

export const formatTimeFromSeconds = (seconds) => {
  const i18n = getI18n()

  const d = Math.floor(seconds / 3600 / 24)
  const h = Math.floor((seconds / 3600) % 24)
  const m = Math.floor((seconds / 60) % 60)
  const s = seconds % 60

  const result = []

  if (d > 0) result.push(`${d} ${i18n.t('common.days')}`)
  if (h > 0) result.push(`${h} ${i18n.t('common.hours')}`)
  if (m > 0) result.push(`${m} ${i18n.t('common.minutes')}`)

  result.push(`${s} ${i18n.t('common.seconds')}`)

  return result.join(', ')
}

export const hasAreaOfOperation = (type) => [
  'catering',
  'convention-bureau',
  'event-facility',
  'personal-service',
  'transfer',
  'convention_bureau',
  'event_facility',
  'personal_service'
].includes(type)

export const getDeliverableSpecificImages = (images, deliverable = '') => {
  if (images && images.length > 0) {
    const deliverableTypeFromParams = getSupplierTypeMapping(deliverable)
    if (images.find(image => image.deliverable_type === deliverableTypeFromParams)) {
      return images.filter(item => item.deliverable_type === deliverableTypeFromParams)
    } else {
      return images
    }
  } else {
    return null
  }
}

export const getBoundingBox = (searchBoundingBox, searchLocation, searchParams, type = 'string') => {
  // Disable geolocation search for supplier type with area of operation
  if (searchParams && searchParams.location && searchParams.type && hasAreaOfOperation(searchParams.type)) {
    return ''
  }

  if (searchBoundingBox && searchLocation && !searchLocation.region) {
    if (type === 'object') {
      return {
        northEast: { lat: searchBoundingBox.northEast.lat, lng: searchBoundingBox.northEast.lng },
        southWest: { lat: searchBoundingBox.southWest.lat, lng: searchBoundingBox.southWest.lng }
      }
    } else {
      return [
        [
          searchBoundingBox.northEast.lat,
          searchBoundingBox.northEast.lng,
          searchBoundingBox.southWest.lat,
          searchBoundingBox.southWest.lng
        ]
      ].toString()
    }
  } else {
    return ''
  }
}

export function getButtonLabel (buttonName, submitStatus, hasUserMadeChanges) {
  const i18n = getI18n()
  if (buttonName === 'submit') {
    switch (submitStatus) {
      case 'nothing-to-save':
        return i18n.t('supplier.edit.button.nothing_to_save')
      case 'in-progress':
        return i18n.t('supplier.edit.button.save_and_continue.saving_label')
      case 'save-successful':
        return i18n.t('supplier.edit.button.save_and_continue.ok_label')
      case 'ready-to-save':
        return i18n.t('supplier.edit.button.save_and_continue.label')
      case 'submit-error':
        return i18n.t('supplier.edit.button.save_and_continue.label')
      default:
        return i18n.t('supplier.edit.button.save_and_continue.label')
    }
  }
  if (buttonName === 'reset') {
    if (hasUserMadeChanges) {
      return i18n.t('supplier.edit.button.reset.label')
    } else {
      return i18n.t('supplier.edit.button.reset.nothing_to_reset')
    }
  }
}

export function getButtonIcon (buttonName, submitStatus, hasUserMadeChanges) {
  if (buttonName === 'submit') {
    switch (submitStatus) {
      case 'nothing-to-save':
        return 'fa fa-exclamation-circle'
      case 'in-progress':
        return 'fa fa-save'
      case 'save-successful':
        return 'fa fa-check'
      case 'ready-to-save':
        return 'fa fa-save'
      case 'submit-error':
        return 'fa fa-save'
      default:
        return 'fa fa-save'
    }
  }
  if (buttonName === 'reset') {
    if (hasUserMadeChanges) {
      return 'fa fa-refresh'
    } else {
      return 'fa fa-times'
    }
  }
}

export function isButtonDisabled (submitStatus) {
  // currently same logic applies to both submit button and reset button
  switch (submitStatus) {
    case 'nothing-to-save':
    case 'in-progress':
    case 'save-successful':
      return true
    case 'ready-to-save':
    case 'submit-error':
      return false
  }
}

export function getMiceMomentPlanEventEncodedParams (miceMoment = null, supplier = null, isLoggedIn = false, queryParams = null) {
  const params = {}
  if (isLoggedIn) {
    params.require_auth = 1
  }

  if (miceMoment) {
    const deliverableType = (miceMoment.deliverable && miceMoment.deliverable.type) || 'hotel'
    const deliverableId = (miceMoment.deliverable && miceMoment.deliverable.supplier && miceMoment.deliverable.supplier.id) || (supplier && supplier.id)
    params.service_types_with_suppliers = { [deliverableType]: deliverableId }
    params.mice_moment_id = miceMoment.id
  }

  return queryString.stringify(params, queryParams)
}

export function getMiceMomentPlanEventEncodedParamsFromHit (details, id, queryParams = null) {
  if (details) {
    const deliverableType = getSupplierTypeFromMapping(details.deliverable_type) || 'hotel'
    const deliverableId = details.deliverable_id || details.supplier_id
    const params = {
      service_types_with_suppliers: {
        [deliverableType]: deliverableId
      },
      mice_moment_id: id
    }
    return queryString.stringify({ ...params, ...queryParams })
  } else {
    return ''
  }
}

export function getSupplierFormattedAddress (address, locale = 'en') {
  if (address) {
    return `${address.street || ''}, ${address.post_code || ''} ${address.city || ''}, ${(address.country && address.country[`name_${locale}`]) || ''}`
  } else {
    return ''
  }
}

export const fetchTopHashtagsFromAlgolia = (lat = 52.52, lng = 13.41) => {
  return new Promise((resolve, reject) => {
    const optionalParams = {
      maxFacetHits: 8,
      aroundLatLng: `${lat}, ${lng}`,
      aroundRadius: 10000
    }
    searchIndexMiceMoment.searchForFacetValues('hashtags.de', '', optionalParams).then(({ facetHits }) => {
      resolve(facetHits.map(hit => { return { name: hit.value, count: hit.count } }))
    }, ({ statusCode, message }) => {
      reject(new Error(`Error ${statusCode}: ${message}`))
    })
  })
}

export const allowedParams = [
  'locale',
  'location',
  'start_date',
  'end_date',
  'number_of_rooms',
  'capacity_number_of_conference_rooms',
  'dehoga',
  'micepoints',
  'capacity_max_square_meter',
  'capacity_event_floor_space',
  'type'
]

export function initAutocompleteService (context) {
  const checkGooglePlaces = () => {
    context.googleMaps = getGoogleMaps()
    context.googlePlaces = context.googleMaps && context.googleMaps.places ? context.googleMaps.places : null
    if (context.googlePlaces) {
      context.autocompleteService = new context.googlePlaces.AutocompleteService()
    } else {
      setTimeout(() => checkGooglePlaces(), 100)
    }
  }
  checkGooglePlaces()
}

export function logAdvertisementEvent (eventType, advertisementId, supplierId) {
  if (eventType === 'city_ad_shown') {
    const lastEvent = window.lastCityAdAdvertisementEvent

    // Prevent duplicate city_ad_shown events due to multiple componentDidMount calls in AdvertisementList.
    // We prevent duplicate events for 5 seconds.
    if (
      lastEvent === undefined ||
      lastEvent.advertisementId !== advertisementId ||
      JSON.stringify(lastEvent.supplierIds) !== JSON.stringify(supplierId) ||
      lastEvent.createdAt < (Date.now() - 5000)
    ) {
      window.lastCityAdAdvertisementEvent = {
        createdAt: Date.now(),
        advertisementId: advertisementId,
        supplierIds: supplierId
      }

      axios.post(`/advertisements/${advertisementId}/logging.json`, { event_type: eventType, supplier_ids: supplierId })
    }
  } else {
    axios.post(`/advertisements/${advertisementId}/logging.json`, { event_type: eventType, supplier_id: supplierId })
  }
}
