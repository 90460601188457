import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { TextInput } from '../../../../FormElements/FormElements'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ButtonWithIcon from '../../../../ButtonWithIcon/ButtonWithIcon'
import axios from 'axios'
import ValidationErrors from '../../../../ValidationErrors/ValidationErrors'
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage'
import { scrollToRef } from '../../../../../shared/utils'
const _ = require('lodash')

class EmailsForm extends Component {
  constructor (props) {
    super(props)

    this.componentRef = React.createRef()

    const { t } = props

    this.state = {
      showSuccessMessage: false,
      isSubmiting: false,
      errors: {},
      supplier: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }

    this.emailsTypes = {
      email: { required: true },
      email_accounting: { required: false },
      email_accounting_2: { required: false },
      email_event: { required: false },
      email_lodging: { required: false },
      email_personal_service: { required: false },
      email_restaurant: { required: false },
      email_location: { required: false },
      email_catering: { required: false },
      email_transfer: { required: false },
      email_event_facility: { required: false },
      email_management: { required: false },
      email_ratefinding: { required: false },
      email_admin: { required: false }
    }

    this.emailLabel = this.emailLabel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  emailLabel (emailKey) {
    const { t } = this.props
    const label = this.props.t('email_for', { correspondence: t(`supplier_emails.${emailKey}`) })
    const requiredLabel = this.emailsTypes[emailKey].required ? '*' : `(${t('optional')})`

    return `${label} ${requiredLabel}`
  }

  componentDidMount () {
    const supplier = {}
    Object.keys(this.emailsTypes).forEach((emailsType) => {
      supplier[emailsType] = this.props.supplier[emailsType]
    })

    this.setState({ supplier })
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      const supplier = {}
      Object.keys(this.emailsTypes).forEach((emailsType) => {
        supplier[emailsType] = this.props.supplier[emailsType]
      })

      this.setState({ supplier })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingFormData()
    }

    if (!this.state.unsavedChanges && !_.isEmpty(prevState.supplier) && JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.handleUnsavedChanges(prevState)
    }
  }

  handleUnsavedChanges (prevState) {
    // This check is to make sure to not detect changing the unsavedChanges attribute as unsaved changes by the user
    // So in this case it means that the user save his changes and the unsavedChanges attribute returned to false
    // Then we don't need to disable the tabs
    if (!prevState.unsavedChanges) {
      this.setState({ unsavedChanges: true, saveButtonDisabled: false })
      this.props.updateHandler(null, 'SettingsPage')
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      showSuccessMessage: false,
      supplier: {
        ...this.state.supplier,
        [name]: value
      }
    })
  }

  handleReset () {
    const { t } = this.props

    this.setState({
      errors: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-spinner fa-spin',
      resetButtonLabel: t('supplier.edit.button.reset.reseting_label')
    })
    this.props.resetHandler()

    setTimeout(() => this.setState({
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }), 2000)
  }

  handleSubmit () {
    this.setState({
      errors: {},
      isSubmiting: true,
      showSuccessMessage: false
    }, function () {
      axios
        .put(`/suppliers/${this.props.supplier.id}.json`, { supplier: this.state.supplier, facet: 'emails', locale: this.props.i18n.language })
        .then(response => {
          scrollToRef(this.componentRef)
          this.setState({ showSuccessMessage: true, isSubmiting: false })
          this.props.updateHandler(response.data, null)
          this.setState({ unsavedChanges: false, saveButtonDisabled: true })
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              scrollToRef(this.componentRef)
              this.setState({ errors: error.response.data.errors, isSubmiting: false })
              break
          }
        })
    })
  }

  render () {
    const { t } = this.props

    return (
      <div ref={this.componentRef}>
        {this.props.enableUnsavedChangesTracker && this.state.unsavedChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={this.state.resetButtonIcon}
              label={this.state.resetButtonLabel}
              className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
              onClick={this.handleReset}
              disabled={this.state.saveButtonDisabled || this.state.isSubmiting}
            />
          </Col>
        ) : null}
        <SuccessMessage message={t('changes_saved')} hidden={!this.state.showSuccessMessage} />
        <ValidationErrors validationErrors={this.state.errors} />

        <Row className='m-0 mt-0 mb-3'>
          <h4>{t('email_for_correspondence')}</h4>
        </Row>
        <Row>
          {['email_management', 'email_ratefinding', 'email_accounting', 'email_admin', 'email_accounting_2'].map((emailKey, idx) => (
            <Col key={emailKey} xs={12} md={6} xl={4}>
              <div className='mt-3 mb-3'>
                <TextInput
                  name={emailKey}
                  error={this.state.errors[emailKey]}
                  value={this.state.supplier[emailKey]}
                  label={this.emailLabel(emailKey)}
                  onChange={this.handleChange}
                  placeholder={t('email_address_placeholder')}
                />
              </div>
            </Col>
          ))}
        </Row>

        <Row className='m-0 mt-0 mb-3'>
          <h4>{t('email_for_request')}</h4>
        </Row>

        <Row>
          {['email', 'email_event', 'email_lodging'].map((emailKey, idx) => (
            <Col key={emailKey} xs={12} md={6} xl={4}>
              <div className='mt-3 mb-3'>
                <TextInput
                  name={emailKey}
                  error={this.state.errors[emailKey]}
                  value={this.state.supplier[emailKey]}
                  label={this.emailLabel(emailKey)}
                  onChange={this.handleChange}
                  placeholder={t('email_address_placeholder')}
                />
              </div>
            </Col>
          ))}
        </Row>

        <Row className='m-0 mt-0 mb-3'>
          <h4>{t('email_for_other')}</h4>
        </Row>

        <Row>
          {['email_personal_service', 'email_restaurant', 'email_location', 'email_catering', 'email_transfer', 'email_event_facility'].map((emailKey, idx) => (
            <Col key={emailKey} xs={12} md={6} xl={4}>
              <div className='mt-3 mb-3'>
                <TextInput
                  name={emailKey}
                  error={this.state.errors[emailKey]}
                  value={this.state.supplier[emailKey]}
                  label={this.emailLabel(emailKey)}
                  onChange={this.handleChange}
                  placeholder={t('email_address_placeholder')}
                />
              </div>
            </Col>
          ))}
        </Row>

        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={4} md={4} sm={8} xs={12}>
            <ButtonWithIcon
              icon={`fa ${this.state.isSubmiting ? 'fa-spinner fa-spin' : 'fa-save'}`}
              label={t('update_email')}
              className='client-theme--font-color  client-theme--bg'
              onClick={this.handleSubmit}
              disabled={this.state.saveButtonDisabled || this.state.isSubmiting}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

EmailsForm.propTypes = {
  supplier: PropTypes.object.isRequired
}

export default withTranslation()(EmailsForm)
